

























































import { Component, Vue, Prop } from 'vue-property-decorator';
import CustomerModel from '@/models/Customer.model';
import PaymentModel from '@/models/Payment.model';
import RefundModel from '@/models/Refund.model';

import ModalRefundPayment from '@/components/Payments/ModalRefundPayment.vue';
import PaymentsRepository from '@/repository/modules/payments.repository';

@Component({
  components: {
    ModalRefundPayment,
  },
})
export default class PaymentDetails extends Vue {
  @Prop({ required: true, type: CustomerModel })
  customer!: CustomerModel;

  @Prop({ required: true, type: PaymentModel })
  payment!: PaymentModel;

  @Prop({ required: true, type: RefundModel })
  refund!: RefundModel;

  showAlertRefund = false;
  isLoadingRefund = false;

  async refundPayment() {
    this.isLoadingRefund = true;
    await PaymentsRepository.refundPayment(this.payment._id);
    this.isLoadingRefund = false;
    this.showAlertRefund = false;
    this.$emit('RELOAD');
  }
}
