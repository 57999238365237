























































import { Component, Vue, Prop } from 'vue-property-decorator';
import InvoiceModel from '@/models/Invoice.model';
import PaymentModel from '@/models/Payment.model';
import RefundModel from '@/models/Refund.model';

import InvoicesRepository from '@/repository/modules/invoices.repository';
import PaymentsRepository from '@/repository/modules/payments.repository';

@Component
export default class ListInvoices extends Vue {
  @Prop({ required: true, type: Array })
  invoicesList!: InvoiceModel[];

  @Prop({ required: true, type: PaymentModel })
  payment!: PaymentModel;

  @Prop({ required: true, type: RefundModel })
  refund!: RefundModel;

  @Prop({ required: true, type: Boolean, default: true })
  loading!: boolean;

  generateInvoiceLoading = false;

  async downloadInvoiceByID(invoice_id: string, id_num: string) {
    await InvoicesRepository.downloadInvoice(invoice_id, id_num);
  }

  async generateInvoice(forced = false) {
    this.generateInvoiceLoading = true;
    PaymentsRepository.createInvoiceForPayment(this.payment._id, forced).then((result) => {
      if (result) {
        this.$emit('RELOAD');
      } else {
        this.generateInvoiceLoading = false;
      }
    });
  }
}
