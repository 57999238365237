













































































import { Component, Vue, Prop } from 'vue-property-decorator';

import InvoicesRepository from '@/repository/modules/invoices.repository';
import PaymentsRepository from '@/repository/modules/payments.repository';
import CustomerModel from '@/models/Customer.model';
import PaymentModel from '@/models/Payment.model';
import InvoiceModel from '@/models/Invoice.model';
import RefundModel from '@/models/Refund.model';

import PaymentDetailsComponent from '@/components/Payments/PaymentDetails.vue';
import ListInvoicesComponent from '@/components/Payments/ListInvoices.vue';

@Component({
  components: {
    PaymentDetailsComponent,
    ListInvoicesComponent,
  },
})
export default class PaymentDetails extends Vue {
  @Prop({ required: true, type: String })
  payment_id!: string;

  customerData!: CustomerModel;
  paymentData!: PaymentModel;
  invoicesList!: InvoiceModel[];
  refundData!: RefundModel;
  loading = true;
  generateInvoiceLoading = false;

  async mounted() {
    await this.getFullDataPayment();
  }

  async getFullDataPayment() {
    this.loading = true;
    const { customer, payment, refund, invoices } = await PaymentsRepository.getFullDataPayment(this.payment_id);
    this.customerData = customer;
    this.paymentData = payment;
    this.invoicesList = invoices;
    this.refundData = refund;
    this.loading = false;
    console.log({ customer, payment, refund, invoices });
  }
}
